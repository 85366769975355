.footerDiv {
  z-index: 2;
  flex-grow: 1;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1.25fr;
  grid-template-areas: "colLeft colMiddle colRight";
  width: 100%;
  max-height: 25vh;
  padding: 1vh 2%;
  color: rgba(200, 200, 200, 0.7);
  font-size: calc(5px + 0.5vw);
  background: #000;
  font-family: europa, "Segoe UI light", Calibri, Helvetica, Arial, sans-serif !important;
}
.footerDiv .footLeft {
  background: #000;
  padding: 1%;
  grid-area: colLeft;
}
.footerDiv .footLeft .logos {
  display: flex;
}
.footerDiv .footLeft .logos .logo {
  width: 50%;
}
.footerDiv .footLeft .logos .logo img {
  width: 9vw;
}
.footerDiv .footMiddle {
  background: #000;
  border-left: 1px solid rgba(200, 200, 200, 0.3);
  padding: 1%;
  grid-area: colMiddle;
}
.footerDiv .footMiddle .title {
  text-align: center;
}
.footerDiv .footMiddle .sitemapDiv {
  xxxpadding: 0 100px;
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow {
  display: flex;
  margin-top: 0.5vw;
  text-align: center;
  line-height: calc(8px + 1vw);
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink {
  flex: 1;
  color: yellow;
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink a {
  text-decoration: none;
  color: rgba(200, 200, 200, 0.7);
  font-size: calc(5px + 0.5vw);
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink a:visited {
  text-decoration: none;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink a:hover {
  xxxtext-decoration: underline;
  color: #666;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .footRight {
  background: #000;
  border-left: 1px solid rgba(200, 200, 200, 0.3);
  padding: 1%;
  grid-area: colRight;
}
.footerDiv .footRight .title {
  text-align: right;
}
.footerDiv .footRight .title a,
.footerDiv .footRight .title a:visited,
.footerDiv .footRight .title a:hover {
  color: whitesmoke;
}
.footerDiv .footRight .atagContact {
  cursor: pointer !important;
  border-bottom: 1px solid transparent;
}
.footerDiv .footRight .atagContact:hover {
  border-bottom: 1px solid #cccccccc;
}
.footerDiv .footRight .contactDiv div {
  text-align: right;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .title {
  padding-bottom: 10px;
  font-size: 1.25vw;
  cursor: default;
  color: whitesmoke;
}

.version {
  position: absolute;
  bottom: 0;
  right: 0;
  color: transparent;
  font-size: 1.2rem;
}

@media (max-width: 999px) {
  .footerDiv {
    display: block !important;
  }

  .footerDiv .footLeft .logos .logo {
    display: flex;
    justify-content: space-evenly;
  }

  .footMiddle,
.footRight {
    border-left: none !important;
  }
}