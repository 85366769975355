.companyDiv {
  padding: 5vh 12vw;
  color: #444 !important;
  background: white;
  font-family: "europa", "Roboto", "Helvetica", "Arial", sans-serif;
}
.companyDiv img {
  border-radius: 10px;
}
@media (max-width: 999px) {
  .companyDiv img {
    width: 100%;
  }
}
@media (min-width: 1921px) {
  .companyDiv img {
    width: 80%;
  }
}
.companyDiv p > div > p:nth-child(5) {
  text-align: center;
}
.companyDiv p > div > p:nth-child(8) {
  text-align: center;
}
.companyDiv p > div > p:nth-child(9) {
  text-align: center;
}
.companyDiv p > div > p:nth-child(10) {
  text-align: center;
}

.pageText h1 {
  margin: calc(4px + 1vw) 0 0 0;
  color: #0066cc;
}
.pageText h2 {
  color: #0066cc;
}
.pageText p {
  margin: 0;
  padding: 0 0 2rem;
  font-size: calc(6px + 1vw);
}

@media (max-width: 1200px) {
  .pageText p {
    font-size: calc(9px + 1vw);
  }
}