.wrapperDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: "europa", "Segoe UI light", "Calibri", "Helvetica", "Arial", sans-serif !important;
  font-weight: 200;
  color: #ccc;
  overflow-x: hidden;
  background: #111;
}
.wrapperDiv h1,
.wrapperDiv h2,
.wrapperDiv h3 {
  font-family: "europa", "Segoe UI light", "Calibri", "Helvetica", "Arial", sans-serif !important;
  font-weight: 200;
}
.wrapperDiv h1 {
  color: #333;
  font-size: calc(8px + 1.4vw);
}
.wrapperDiv h2 {
  color: #888;
  font-size: calc(8px + 1.35vw);
}
.wrapperDiv h3 {
  color: #333;
  font-size: calc(8px + 1.3vw);
}
.wrapperDiv p {
  font-size: calc(10px + 1vw);
  font-family: "europa", "Segoe UI light", "Calibri", "Helvetica", "Arial", sans-serif !important;
  font-weight: 200;
}