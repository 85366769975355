.backdropDiv {
  position: relative;
  left: 0;
  min-height: calc(100vh - 266px);
  width: 100vw;
  /*background: url(../../user_uploads/img/bgFlot02.webp) center center / cover no-repeat;*/
}
.backdropDiv .prodsBG {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  max-height: 100vh;
  min-height: calc(100vh - 270px);
}
.backdropDiv .productsDiv {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 270px);
  padding: 5vh 12vw;
  color: #444 !important;
  background: rgba(230, 230, 230, 0.85);
}
.backdropDiv .productsDiv .buttonsDiv {
  position: absolute;
  z-index: 1;
  padding: 7vh 5vw;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}
.backdropDiv .productsDiv .buttonsDiv button {
  width: 600px;
  margin: 10px;
  padding: 2vh 0;
  border: 1px solid rgba(100, 100, 100, 0.3);
}
.backdropDiv .productsDiv .buttonsDiv button a {
  text-decoration: none;
  font-size: 1.3rem;
  color: #555;
}
.backdropDiv .productsDiv .searchDiv {
  position: absolute;
  z-index: 2;
  width: 75%;
  display: flex;
  padding: 0 20vw;
  font-size: 1.3em;
}
.backdropDiv .productsDiv .searchDiv .srxLeft {
  flex-basis: 30%;
  text-align: right;
  padding-right: 30px;
}
.backdropDiv .productsDiv .searchDiv .srxRight {
  flex-basis: 70%;
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxInputDiv {
  xxxborder: 1px solid blue;
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxInputDiv .srxInputField {
  width: 100%;
  outline: unset;
  padding: 3px 10px;
  font-size: 1.1em;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 3px;
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxInputDiv .srxInputField:focus {
  border: 1px solid rgba(50, 100, 200, 0.5);
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxInputDiv .srxInputField::placeholder {
  color: #ccc;
  font-family: "Segoe UI light", "Segoe UI", Arial, Helvetica, sans-serif;
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxResultsDiv {
  background: rgba(250, 250, 250, 0.9);
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxResultsDiv ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxResultsDiv ul li {
  padding: 0 10px;
  cursor: pointer;
}
.backdropDiv .productsDiv .searchDiv .srxRight .srxResultsDiv ul li:hover {
  background: #4774b354 !important;
}
.backdropDiv .productsDiv h3 {
  margin: 0;
  padding: 0;
}
.backdropDiv .productsDiv > p > div > p {
  padding-top: 0 !important;
}