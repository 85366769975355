.minWidth {
  min-width: 110px !important;
  margin-bottom: 5px !important;
}

.homeContent p {
  margin-top: 1.6rem;
  font-size: 1.2rem;
}

.homeWrap {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 58px);
  background: #444;
}
.homeWrap .s1SplashDiv {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  /*  DISABLED  DISABLED  DISABLED  DISABLED  DISABLED  DISABLED  */
  /*  DISABLED  DISABLED  DISABLED  DISABLED  DISABLED  DISABLED  */
}
.homeWrap .s1SplashDiv .layerOne {
  /*  YouTube video in an iFrame  */
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.homeWrap .s1SplashDiv .layerOne img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  xxxanimation: zoomToFullScreen 16s linear 0s infinite backwards;
}
.homeWrap .s1SplashDiv .layerTwo {
  /*  Solid background that fades-in to hide the video as it cycles  */
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.homeWrap .s1SplashDiv .layerTwo .banner {
  width: 100%;
  height: 100%;
}
.homeWrap .s1SplashDiv .layerTwo .banner .bgvid {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  background: transparent url(../../../user_uploads/img/HomePagePoster.webp) no-repeat;
  background-position: center center;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
.homeWrap .s1SplashDiv .layerThree {
  /*  Still-frame (single image) from video starting point  */
  display: none !important;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 11, 29, 0.99);
  xxxanimation: fadeInAt14s 16s linear 0s infinite;
}
.homeWrap .s1SplashDiv .layerThree img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url();
  xxxanimation: slideUpAt14s 16s linear 0s infinite;
}
.homeWrap .s1SplashDiv .layerFour {
  /*  Home Page text that sits atop the video  */
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: grayscale(0.1) blur(1px) opacity(80%);
}
.homeWrap .s1SplashDiv .layerFour .cqLogo {
  width: 100%;
  margin: 4vw 0 0 2vw;
}
.homeWrap .s1SplashDiv .layerFour .cqLogo img {
  width: 15vw;
}
.homeWrap .s1SplashDiv .layerFour .s1BlurbDiv {
  text-align: center;
  text-shadow: 1px 1px 2px #232323;
}
.homeWrap .s1SplashDiv .layerFour .s1BlurbDiv h2 {
  margin: 0;
  padding: 14vh 24vw 4vh;
  color: #fff;
  text-transform: uppercase;
  font-family: "Libre Baskerville", serif !important;
  font-size: 2.75rem;
  font-weight: 700;
  line-height: 3.5rem;
}
.homeWrap .s1SplashDiv .layerFour .s1BlurbDiv h3 {
  margin: 0;
  padding: 0 10vw 5vh;
  color: #fff;
  font-family: "europa", sans-serif;
  font-size: 1.65rem;
  font-weight: 400;
}
.homeWrap .s1SplashDiv .layerFour .s1BtnProdsDIV {
  display: flex;
  justify-content: center;
}
.homeWrap .s2GoalDiv {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: white;
}
.homeWrap .s2GoalDiv .s2GoalInner {
  height: 100%;
  width: 65%;
  margin: 0 auto;
  color: #333;
  font-family: "europa", sans-serif;
}
.homeWrap .s2GoalDiv .s2GoalInner h2 {
  margin: 0;
  padding: 11vh 10vw 4vh;
  color: inherit;
  font-family: inherit;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 2.4rem;
}
.homeWrap .s2GoalDiv .s2GoalInner h3 {
  margin: 0;
  padding: 0 10vw 5vh;
  color: inherit;
  font-family: inherit;
  font-size: 1.85rem;
  font-weight: 200;
}
.homeWrap .s2GoalDiv .s2GoalInner .s2GoalImgDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10vh;
}
.homeWrap .s2GoalDiv .s2GoalInner .s2GoalImgDiv img {
  width: 35%;
}
.homeWrap .s3HistoryDiv {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: #eaeaea;
  display: flex;
}
.homeWrap .s3HistoryDiv .s3SideLeft {
  flex: 1;
  flex-basis: 50%;
  height: calc(900px + 1.5vw);
  background-image: url(../../../public/img/plant_workers_full.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.homeWrap .s3HistoryDiv .s3SideRight {
  flex: 1;
  flex-basis: 50%;
  padding: 2vh 7vw;
}
.homeWrap .s3HistoryDiv .s3SideRight .logoDiv {
  padding: 2rem 0 1rem;
  text-align: center;
}
.homeWrap .s3HistoryDiv .s3SideRight .logoDiv img {
  width: 40%;
}
.homeWrap .s3HistoryDiv .s3SideRight h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
  line-height: calc(8px + 1.75vw);
}
.homeWrap .s3HistoryDiv .s3SideRight h5 {
  margin: 0;
  padding: 0 0 6vh;
  color: #333;
  font-family: "europa", serif;
  font-size: 2.3rem;
  font-weight: 400;
}
.homeWrap .s3HistoryDiv .s3SideRight p {
  margin-top: 2rem;
  padding-top: 0;
  font-size: 1.5rem;
}
.homeWrap .s3HistoryDiv .s3SideRight button {
  margin: 6vh 0 4vh;
}
.homeWrap .s4FlottecHomeContent {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: rgba(0, 11, 29, 0.92);
}
.homeWrap .s4FlottecHomeContent .homeContent {
  height: 100%;
  width: 65%;
  margin: 0 auto;
  padding: 10vh 0;
  color: #ccc;
  font-family: "europa", sans-serif;
  font-weight: 200;
  font-size: 3rem;
}
.homeWrap .s4FlottecHomeContent .homeContent p {
  margin-top: 0;
  padding-top: 1.5rem;
  font-size: 1.65rem;
}

.homeReqQuote {
  color: #888 !important;
}

.alert {
  background: red;
  color: yellow !important;
}

@keyframes fadeFromBlack {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAt14s {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
  }
  6% {
    opacity: 1;
  }
  9% {
    opacity: 1;
  }
  13% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideUpAt14s {
  0% {
    top: 100%;
    left: 100%;
  }
  5% {
    top: 100%;
    left: 100%;
  }
  14% {
    top: 0;
    left: 0;
  }
  16% {
    top: 0;
    left: 0;
  }
  17% {
    top: 100%;
    left: 100%;
  }
  100% {
    top: 100%;
    left: 100%;
  }
}
@keyframes zoomToFullScreen {
  0% {
    transform: scale(0);
  }
  2% {
    transform: scale(0);
  }
  6% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
/* - - - - - - - - - - */
.btnChemiqaA {
  touch-action: inherit;
}

.btnChemiqa {
  display: flex;
  justify-content: center;
  background-color: #2fc4dc;
  color: #fff;
  border-radius: 0;
  padding: 0.75em 2.75em;
  font-size: 1.5rem;
  xxxfont-weight: 600;
  transition: all 0.3s;
  font-family: "europa", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.btnChemiqa:hover {
  background-color: #2fc4dcbd;
}

.ql-align-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  text-shadow: grey;
}

a {
  color: #333;
  text-decoration: none;
}
a:visited {
  color: #333;
}

.requestQuoteDiv {
  background: url(../../../public/img/request_quote.jpg) no-repeat center center fixed;
  background-size: cover;
}

.requestQuoteDiv .reqQuoteInner {
  text-align: center;
  padding: 15vh 0;
  text-shadow: 1px 1px 2px #232323;
  background: rgba(0, 0, 0, 0.8);
}

.requestQuoteDiv .reqQuoteInner h5 {
  padding: 2vh 0 5vh;
  color: white;
}

.requestQuoteDiv .btnLetsTalkDiv {
  display: flex;
  justify-content: center;
}