@charset "UTF-8";
.servicesDiv {
  font-family: "europa", serif;
  font-size: 7rem;
  color: #333;
}
.servicesDiv .s1TopDiv {
  position: relative;
  width: 100%;
  height: 30vh;
}
.servicesDiv .s1TopDiv .s1TopBannerPhoto {
  width: 100%;
  height: 100%;
  background: url(../../../public/img/plant01.webp) no-repeat center center/cover scroll;
}
.servicesDiv .s1TopDiv .s1TopOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.servicesDiv .s1TopDiv .s1TopCqLogo {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  margin-top: 2vh;
}
.servicesDiv .s1TopDiv .s1TopCqLogo img {
  width: 400px;
}
.servicesDiv .s2Overview {
  background: white;
}
.servicesDiv .s2Overview .s2OverviewInner {
  width: 65%;
  margin: 0 auto;
}
.servicesDiv .s2Overview .s2OverviewInner h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
}
.servicesDiv .s2Overview .s2OverviewInner p {
  font-size: 1.8rem;
  font-weight: 400;
}
.servicesDiv .s2Overview .s2OverviewInner .s2List {
  font-size: calc(10px + 1vw);
}
.servicesDiv .s2Overview .s2OverviewInner .s2List ul li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #222;
  position: absolute;
  left: -1em;
}
.servicesDiv .s2Overview .s2OverviewInner .s2List ul li a:hover {
  color: #777;
}
.servicesDiv .s2Overview .s2OverviewInner .btnReqQuoteDiv {
  padding: 0 0 10vh;
}
.servicesDiv .s9RequestQuote {
  background: url(../../../public/img/request_quote.jpg) no-repeat center center fixed;
  background-size: cover;
}
.servicesDiv .s9RequestQuote .s9ReqQuoteInner {
  text-align: center;
  padding: 15vh 0;
  text-shadow: 1px 1px 2px #232323;
  backdrop-filter: grayscale(0.9) blur(1px) opacity(99%);
}
.servicesDiv .s9RequestQuote .s9ReqQuoteInner .btnLetsTalkDiv {
  display: flex;
  justify-content: center;
}

/* - - - - - - - - - - */
.btnChemiqa {
  display: flex;
  justify-content: center;
  background-color: #2fc4dc;
  color: #fff;
  border-radius: 0;
  padding: 0.75em 2.75em;
  font-size: calc(10px + 1vw);
  xxxfont-weight: 600;
  transition: all 0.3s;
  font-family: "europa", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.btnChemiqa:hover {
  background-color: #2fc4dcbd;
}

.ql-align-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  text-shadow: grey;
}

a {
  color: #333;
  text-decoration: none;
}
a:visited {
  color: #333;
}

.requestQuoteDiv {
  background: url(../../../public/img/request_quote.jpg) no-repeat center center fixed;
  background-size: cover;
}
.requestQuoteDiv .reqQuoteInner {
  text-align: center;
  padding: 15vh 0;
  text-shadow: 1px 1px 2px #232323;
  background: rgba(0, 0, 0, 0.8);
}
.requestQuoteDiv .reqQuoteInner h3 {
  color: #aaa;
}
.requestQuoteDiv .reqQuoteInner h5 {
  padding: 2vh 0 5vh;
  color: white;
  font-family: "europa", Roboto, Arial, Helvetica, serif;
}

.requestQuoteDiv .btnLetsTalkDiv {
  display: flex;
  justify-content: center;
}