:export {
  mxDarkerGold: #81610f;
  mxDarkerGoldHover: #866510;
  mxDarkGold: #ab8514;
  mxLightGold: #c29e14;
  mxYellow: #e0c22e;
  mxWhite: #e9f0f5;
  mxGreen: #1e7b1e;
  mxBlue: #004b6a;
  mxDarkBlue: #00294a;
  mxDepressedBgBlue: #00294b;
  mxDepressedFgBlue: #73879a;
  mxBtnGradientLite: linear-gradient(-180deg, #af8519, #81610f 90%);
  mxBtnGradientDark: linear-gradient(-180deg, #daa520, #a87f16 90%);
  mxBtnGradientGreenLite: linear-gradient(-180deg, #84e184, #1e7b1e 90%);
  mxBtnGradientGreenDark: linear-gradient(-180deg, #32cd32, #1e7b1e 90%);
  confirmGreen: #00b300;
  warnRed: #b80000;
  closeXButtonColor: #ff2700;
  closeXButtonHoverColor: #ff8c8c;
  headerColor: darkslategrey;
  dialogBackgroundColor: #272727;
}

.button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: white;
}
.button[data-background=regular] {
  background-color: #81610f;
  background-image: linear-gradient(-180deg, #c29e14, #ab8b12 90%);
}
.button[data-background=regular]:hover {
  background-color: #d9b116;
  background-image: linear-gradient(-180deg, #d9b116, #c29e14 90%);
}
.button:disabled {
  color: rgba(211, 210, 210, 0.781);
  background-color: dimgray;
  background-image: linear-gradient(-180deg, dimgray, #535353 90%);
  cursor: no-drop;
}
.button:disabled:hover {
  color: rgba(211, 210, 210, 0.781);
  background-color: dimgray;
  background-image: linear-gradient(-180deg, dimgray, #535353 90%);
}
.button[data-background=green], .button[data-background=bubble-green] {
  color: white;
  background-color: #32cd32;
  background-image: linear-gradient(-180deg, #32cd32, #1e7b1e 90%);
}
.button[data-background=green]:hover, .button[data-background=bubble-green]:hover {
  background-color: #28a428;
  background-image: linear-gradient(-180deg, #84e184, #1e7b1e 90%);
}
.button[data-background=bubble-green-blink] {
  animation: buttonGreenBlink 0.2s infinite;
}
.button[data-background=red] {
  background-color: #b80000;
  background-image: linear-gradient(-180deg, #eb0000, #850000 90%);
}
.button[data-background=red]:hover {
  background-color: #d20000;
  background-image: linear-gradient(-180deg, #eb0000, #b80000 90%);
}
.button[data-size=slim] {
  padding: 2px 8px;
}
.button[data-size=large] {
  padding: 30px 20px;
  border-radius: 5px;
}

.depressed {
  outline: none;
  text-shadow: 1px 1px transparent, -1px -1px black;
  border-style: solid;
  border-width: 1px;
  border-color: #333 #999 #999 #333;
  color: #d9c5a1;
  background-color: #00294a;
  background-image: none;
}
.depressed:hover {
  background-color: #00294b;
  background-image: none;
  border: 1px solid #ddc;
}

@keyframes buttonGreenBlink {
  from {
    background-color: #d87634;
    background-image: linear-gradient(-180deg, #f59f65, #b86127 90%);
  }
  to {
    background-color: #32cd32;
    background-image: linear-gradient(-180deg, #84e184, #1e7b1e 90%);
  }
}