.blacklistedDomainDiv {
  padding: 15vh 20vw;
  color: #5f7282;
  font-size: 1.4rem;
}
.blacklistedDomainDiv .greeting {
  margin-bottom: 15px;
  font-style: normal;
}
.blacklistedDomainDiv .confirmation {
  margin-bottom: 15px;
  font-style: normal;
}
.blacklistedDomainDiv .message {
  margin-bottom: 15px;
  font-style: normal;
}
.blacklistedDomainDiv .closing .ty {
  margin-bottom: 15px;
  font-style: italic;
}

.fgBlue {
  color: #0062b7;
}