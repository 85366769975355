@charset "UTF-8";
.productsDiv {
  font-family: "europa", serif;
  font-size: 7rem;
  color: #333;
}
.productsDiv .s1TopDiv {
  position: relative;
  width: 100%;
  height: 30vh;
}
.productsDiv .s1TopDiv .s1TopBannerPhoto {
  width: 100%;
  height: 100%;
  background: url(../../../public/img/pallets_banner.jpg) no-repeat center center/cover scroll;
}
.productsDiv .s1TopDiv .s1TopOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.productsDiv .s1TopDiv .s1TopCqLogo {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  margin-top: 2vh;
}
.productsDiv .s1TopDiv .s1TopCqLogo img {
  width: 400px;
}
.productsDiv .s2Overview {
  background: white;
}
.productsDiv .s2Overview .s2OverviewInner {
  width: 68%;
  margin: 0 auto;
}
.productsDiv .s2Overview .s2OverviewInner h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-size: calc(8px + 1.5vw);
}
.productsDiv .s2Overview .s2OverviewInner p {
  margin: 0;
  padding: 0;
  font-size: calc(10px + 1vw);
  font-weight: 400;
}
.productsDiv .s2Overview .s2OverviewInner .s2List {
  max-width: calc(300px + 10vw);
  font-size: calc(10px + 1vw);
}
.productsDiv .s2Overview .s2OverviewInner .s2List ul li {
  cursor: pointer;
}
.productsDiv .s2Overview .s2OverviewInner .s2List ul li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #222;
  position: absolute;
  left: -1em;
}
.productsDiv .s2Overview .s2OverviewInner .s2List ul li a:hover {
  color: #777;
}
.productsDiv .s2Overview .s2OverviewInner .btnReqQuoteDiv {
  xxxmax-width: 40%;
  padding: 0 0 10vh;
}
.productsDiv .s2Overview .s2OverviewInner .btnReqQuoteDiv a button span {
  font-size: calc(12px + 1vw);
  line-height: calc(14px + 1vw);
}
.productsDiv .s3Collectors {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: #eaeaea;
  display: flex;
}
.productsDiv .s3Collectors .s3SideLeft {
  flex: 1;
  flex-basis: 50%;
  height: 800px;
  background-image: url(../../../public/img/float_cell03.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.productsDiv .s3Collectors .s3SideRight {
  flex: 1;
  flex-basis: 50%;
  padding: 2vh 7vw;
}
.productsDiv .s3Collectors .s3SideRight h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
  line-height: 5rem;
  cursor: pointer;
}
.productsDiv .s3Collectors .s3SideRight p {
  margin: 0;
  padding: 0;
  font-size: calc(10px + 1vw);
  font-weight: 200;
}
.productsDiv .s3Collectors .s3SideRight > p > div > p {
  margin: 2.5vh 0;
}
.productsDiv .s3Collectors .s3SideRight .s3List {
  font-size: calc(10px + 1vw);
}
.productsDiv .s3Collectors .s3SideRight .s3List ul li {
  cursor: pointer;
}
.productsDiv .s3Collectors .s3SideRight .s3List ul li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #222;
  position: absolute;
  left: -1em;
}
.productsDiv .s3Collectors .s3SideRight .s3List ul li a:hover {
  color: #777;
}
.productsDiv .s4Frothers {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: white;
  display: flex;
}
.productsDiv .s4Frothers .s4SideRight {
  flex: 1;
  flex-basis: 50%;
  height: 900px;
  background-image: url(../../../public/img/float_cell01_crop.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.productsDiv .s4Frothers .s4SideLeft {
  flex: 1;
  flex-basis: 50%;
  padding: 2vh 7vw;
}
.productsDiv .s4Frothers .s4SideLeft h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
  line-height: 5rem;
  cursor: pointer;
}
.productsDiv .s4Frothers .s4SideLeft p {
  margin: 0;
  padding: 0;
  font-size: calc(10px + 1vw);
  font-weight: 200;
}
.productsDiv .s4Frothers .s4SideLeft > p > div > p {
  margin: 2.5vh 0;
}
.productsDiv .s4Frothers .s4SideLeft .s4List {
  font-size: calc(10px + 1vw);
}
.productsDiv .s4Frothers .s4SideLeft .s4List ul li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #222;
  position: absolute;
  left: -1em;
}
.productsDiv .s4Frothers .s4SideLeft .s4List ul li a:hover {
  color: #777;
}
.productsDiv .s5ProcessingAids {
  padding: 10vh 15vw;
  background: #eaeaea;
}
.productsDiv .s5ProcessingAids h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
  line-height: 5rem;
  cursor: pointer;
}
.productsDiv .s5ProcessingAids p {
  margin: 0;
  padding: 0;
  font-size: calc(10px + 1vw);
  font-weight: 200;
}
.productsDiv .s5ProcessingAids > p > div > p {
  margin: 2.5vh 0;
}
.productsDiv .s5ProcessingAids .s5List {
  font-size: calc(10px + 1vw);
}
.productsDiv .s5ProcessingAids .s5List ul li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #222;
  position: absolute;
  left: -1em;
}
.productsDiv .s5ProcessingAids .s5List ul li a:hover {
  color: #777;
}
.productsDiv .s6Surfactants {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: white;
  display: flex;
}
.productsDiv .s6Surfactants .s6SideRight {
  flex: 1;
  flex-basis: 50%;
  height: 700px;
  background-image: url(../../../public/img/concentrator_crop.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.productsDiv .s6Surfactants .s6SideLeft {
  flex: 1;
  flex-basis: 50%;
  padding: 2vh 7vw;
}
.productsDiv .s6Surfactants .s6SideLeft h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
  line-height: 2.5rem;
  cursor: pointer;
}
.productsDiv .s6Surfactants .s6SideLeft p {
  margin: 0;
  padding: 0;
  font-size: calc(10px + 1vw);
  font-weight: 200;
}
.productsDiv .s6Surfactants .s6SideLeft > p > div > p {
  margin: 2.5vh 0;
}
.productsDiv .s7Depressants {
  padding: 10vh 15vw;
  background: #eaeaea;
}
.productsDiv .s7Depressants h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
  line-height: 5rem;
  cursor: pointer;
}
.productsDiv .s7Depressants p {
  padding: 0;
  margin: 2.5vh 0;
  font-size: calc(10px + 1vw);
  font-weight: 200;
}
.productsDiv .s8AdvancedProducts {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: fit-content;
  color: #222;
  background: white;
  display: flex;
}
.productsDiv .s8AdvancedProducts .s8SideLeft {
  flex: 1;
  flex-basis: 50%;
  min-height: 650px;
  background-image: url(../../../public/img/science_crop.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.productsDiv .s8AdvancedProducts .s8SideRight {
  flex: 1;
  flex-basis: 50%;
  padding: 2vh 7vw;
}
.productsDiv .s8AdvancedProducts .s8SideRight h3 {
  margin: 0;
  padding: 6vh 0 2vh;
  color: #333;
  text-transform: uppercase;
  font-family: "europa", serif;
  font-size: calc(8px + 1.5vw);
}
.productsDiv .s8AdvancedProducts .s8SideRight p {
  padding: 0;
  margin: 2.5vh 0;
  font-size: calc(10px + 1vw);
  font-weight: 200;
}

.aLink {
  text-decoration: none;
  color: green;
}
.aLink button span {
  font-size: calc(12px + 1vw);
  line-height: calc(14px + 1vw);
}

/* - - - - - - - - - - */
.btnChemiqa {
  display: flex;
  justify-content: center;
  background-color: #2fc4dc;
  color: #fff;
  border-radius: 0;
  padding: 0.75em 2.75em;
  font-size: 1.5rem;
  xxxfont-weight: 600;
  transition: all 0.3s;
  font-family: "europa", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.btnChemiqa:hover {
  background-color: #2fc4dcbd;
}

.ql-align-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  text-shadow: grey;
}

a {
  color: #333;
  text-decoration: none;
}
a:visited {
  color: #333;
}

.requestQuoteDiv {
  background: url(../../../public/img/request_quote.jpg) no-repeat center center fixed;
  background-size: cover;
}

.requestQuoteDiv .reqQuoteInner {
  text-align: center;
  padding: 15vh 0;
  text-shadow: 1px 1px 2px #232323;
  background: rgba(0, 0, 0, 0.8);
}

.requestQuoteDiv .reqQuoteInner h5 {
  padding: 2vh 0 5vh;
  color: white;
}

.requestQuoteDiv .btnLetsTalkDiv {
  display: flex;
  justify-content: center;
}