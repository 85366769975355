.overlay {
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.loginModal {
  z-index: 9;
  position: absolute;
  top: 20vh;
  width: calc(100px + 40vw);
  height: fit-content;
  padding: 0.5vw;
  color: #444;
  background: white;
}
.loginModal .logmodTop {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}
.loginModal .logmodTop .logmodTopText {
  width: 80%;
  font-size: 1.2em;
  font-weight: bold;
}
.loginModal .logmodTop .logmodCloseX {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  padding-bottom: 3px;
  color: white;
  background: #0073ea;
  border: 2px solid #0073ea;
}
.loginModal .logmodTop .logmodCloseX:hover {
  cursor: pointer;
  color: #0073ea;
  font-weight: bold;
  background: white;
}
.loginModal .logmodBody {
  display: flex;
  padding: 10px;
  font-size: 0.9em;
}
.loginModal .logmodBody .logmodBodyLeft {
  flex: 1;
  max-width: 25vw;
}
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow input[type=text],
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow input[type=password] {
  width: 100%;
}
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow input[type=text]::placeholder,
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow input[type=password]::placeholder {
  font-size: 0.8em;
  color: #bbb;
}
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow .logmodFRLTitle {
  width: 28%;
  text-align: left;
}
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow .logmodFRLInput {
  flex: 1;
  max-width: 70%;
}
.loginModal .logmodBody .logmodBodyLeft .logmodFieldRow .forgotPasswordDiv {
  margin-bottom: 10px;
}
.loginModal .logmodBody .logmodBodyLeft .logmodBtnLoginDiv {
  display: flex;
  justify-content: flex-end;
  padding-right: 2vw;
}
.loginModal .logmodBody .logmodBodyRight {
  flex: 1;
  padding-left: 2vw;
  border-left: 1px solid #ccc;
}
.loginModal .logmodBody .logmodBodyRight .logmodUL {
  padding-inline-start: 2vw;
  font-size: calc(0.7rem + 0.3vw);
  line-height: calc(0.8rem + 0.3vw);
}
.loginModal .logmodBody .logmodBodyTitle {
  margin-bottom: 1vw;
  font-size: calc(7px + 1vw);
}
.loginModal .logmodBody .logmodBtnRight {
  position: absolute;
  right: 2vw;
  bottom: 0;
}
.loginModal .logmodBody .ibutton {
  margin: 0;
  padding: 5px 10px;
  color: white;
  xxxfont-size: 1.1em;
  xxxfont-weight: bold;
  background: #0073ea;
  border: 2px solid #0073ea;
  border-radius: unset;
}
.loginModal .logmodBody .ibutton:hover {
  cursor: pointer;
  color: #0073ea;
  background: white;
}

.error {
  width: 70%;
  padding: 5px;
  margin: 10px 0 10px 20px;
  text-align: center;
  color: yellow;
  cursor: default;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.9);
}

.loadingBanner {
  position: absolute;
  height: 1%;
  background: skyblue;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.form-control {
  height: 34px;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  xxxborder-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

@media (max-width: 999px) {
  .loginModal {
    left: 10vw;
    width: 80vw;
  }

  .logmodFRLTitle {
    display: none !important;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .loginModal {
    left: 15vw;
    width: 70vw;
  }

  .logmodFRLTitle {
    display: none !important;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .loginModal {
    left: 20vw;
    width: 60vw;
  }
}