.svgMapDiv {
  font-size: 1em;
}
.svgMapDiv .st0 {
  fill: #c2eaff;
}
.svgMapDiv .st1 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.svgMapDiv .st2 {
  fill: #f3f3f0;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linejoin: round;
}
.svgMapDiv .st3 {
  fill: #064eac;
}
.svgMapDiv .st4 {
  fill: none;
  stroke: #4f97f5;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.svgMapDiv .st5 {
  fill: none;
}
.svgMapDiv .st6 {
  fill: #ffab36;
}
.svgMapDiv .st7 {
  fill: #0eaebf;
}
.svgMapDiv .st8 {
  fill: #757575;
}
.svgMapDiv .st9 {
  fill: #dbdbdb;
}
.svgMapDiv .st10 {
  fill: #a30700;
}
.svgMapDiv .st12 {
  fill: #a30700;
  stroke: #a30700;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.svgMapDiv .st22 {
  fill: #00a336;
  stroke: #00a336;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.svgMapDiv .stUSAFat {
  fill: none;
  stroke: #064eac;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stMxFat {
  fill: none;
  stroke: #00a336;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stMxThin {
  fill: #00a336;
}
.svgMapDiv .stClFat {
  fill: none;
  stroke: #e60012;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stClThin {
  fill: #e60012;
}
.svgMapDiv .stCaFat {
  fill: none;
  stroke: #272a68;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stKTFat {
  fill: none;
  stroke: #f14e23;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stCnFat {
  fill: none;
  stroke: #e60012;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stMetrix {
  fill: none;
  stroke: #c29402;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.svgMapDiv .stSinoz {
  fill: none;
  stroke: #0052aa;
  stroke-width: 1;
  stroke-miterlimit: 10;
}

.logoFlotUsa {
  opacity: 0;
  animation: logoFlotUsa 0.1s cubic-bezier(0.64, 0, 0.78, 0) 0.5s forwards;
}

.logoChemiqa {
  opacity: 0;
  animation: logoFlotUsa 0.2s cubic-bezier(0.64, 0, 0.78, 0) 0.55s forwards;
}

.logoFlorrea {
  opacity: 0;
  animation: logoFlorrea 0.3s cubic-bezier(0.64, 0, 0.78, 0) 0.6s forwards;
}

.logoSinoz {
  opacity: 0;
  animation: logoSinoz 0.4s cubic-bezier(0.64, 0, 0.78, 0) 0.65s forwards;
}

.logoKemtec {
  opacity: 0;
  animation: logoKemtec 0.5s cubic-bezier(0.64, 0, 0.78, 0) 0.7s forwards;
}

.logoKemtecAfrica {
  opacity: 0;
  animation: logoKemtecAfrica 0.5s cubic-bezier(0.64, 0, 0.78, 0) 0.7s forwards;
}

.logoFlotChil {
  opacity: 0;
  animation: logoFlotChil 0.6s cubic-bezier(0.64, 0, 0.78, 0) 0.75s forwards;
}

.logoFlotMex {
  opacity: 0;
  animation: logoFlotMex 0.7s cubic-bezier(0.64, 0, 0.78, 0) 0.8s forwards;
}

.logoMetrix {
  opacity: 0;
  animation: logoMetrix 0.8s cubic-bezier(0.64, 0, 0.78, 0) 0.85s forwards;
}

.allLines {
  stroke: #555 !important;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear 2s forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes logoFlotUsa {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoChemiqa {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoFlorrea {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoSinoz {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoKemtec {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoKemtecAfrica {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoFlotChil {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoFlotMex {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logoMetrix {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes allLines {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}