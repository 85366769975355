.userRegistrationReceivedDiv {
  padding: 15vh 20vw;
  color: #5f7282;
  font-size: 1.4rem;
}
.userRegistrationReceivedDiv .greeting {
  margin-bottom: 15px;
  font-style: normal;
}
.userRegistrationReceivedDiv .confirmation {
  margin-bottom: 15px;
  font-style: normal;
}
.userRegistrationReceivedDiv .message {
  margin-bottom: 15px;
  font-style: normal;
}
.userRegistrationReceivedDiv .closing .ty {
  margin-bottom: 15px;
  font-style: italic;
}

.fgBlue {
  color: #0062b7;
}

.urgentEmailA {
  color: #2fc4dccc;
}