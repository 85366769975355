.backdropDiv {
  position: relative;
  left: 0;
  min-height: 95vh;
  width: 100vw;
}
.backdropDiv .subcategoriesDiv {
  z-index: 2;
  xxxposition: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 95vh;
  padding: 0 12vw 5vh;
  color: #444 !important;
  background: rgba(230, 230, 230, 0.85);
}
.backdropDiv .subcategoriesDiv > div > p {
  padding: 0;
}
.backdropDiv .subcategoriesDiv > div > p > div > p {
  padding: 0;
  font-size: calc(6px + 1vw);
}
.backdropDiv .subcategoriesDiv .blurbDiv {
  xxxmargin-top: calc(12px + 1vw);
  margin-top: 55px;
}
.backdropDiv .subcategoriesDiv .blurbDiv .name {
  font-size: calc(9px + 1vw);
}
.backdropDiv .subcategoriesDiv .blurbDiv .pdf {
  font-size: calc(6px + 1vw);
}
.backdropDiv .subcategoriesDiv .buttonsDiv {
  padding: 0 5vh 5vh;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 45vh;
  overflow-y: auto;
}
.backdropDiv .subcategoriesDiv .buttonsDiv button {
  width: 300px;
  margin: 6px;
  padding: 2vh 0;
  border: 1px solid rgba(100, 100, 100, 0.3);
}

.pdfLinkDiv > div {
  display: inline-block;
  padding: 2px 20px;
  margin-right: 30px;
  border: 1px solid rgba(150, 150, 150, 0.5);
  border-radius: 5px;
}
.pdfLinkDiv > div:hover {
  background: rgba(200, 200, 200, 0.3);
}
.pdfLinkDiv > div a {
  color: #4774b3;
  text-decoration: none;
}
.pdfLinkDiv > div a:hover {
  color: #0068ff;
}

.lookLikeAnchor {
  color: #3f51b5;
}
.lookLikeAnchor:hover {
  cursor: pointer;
  text-decoration: underline;
}

.catPDF {
  display: inline-block;
  padding: 0 2vw 2vh;
  font-size: calc(6px + 1vw);
}
.catPDF a {
  color: #3f51b5;
  text-decoration: none;
}
.catPDF a:hover {
  text-decoration: underline;
}

.mustEmailDlg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  font-size: 5rem;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.mustEmailDlg .mustEmailInner {
  position: relative;
  max-width: 60vw;
  padding: 10vh;
  font-family: "Segoe UI Light", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  background: white;
}
.mustEmailDlg .mustEmailInner .meCloseX {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 2rem;
  padding: 0 15px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.mustEmailDlg .mustEmailInner .meCloseX:hover {
  color: #3f51b5;
  border: 1px solid #3f51b5;
}
.mustEmailDlg .mustEmailInner .meBody {
  text-align: center;
}
.mustEmailDlg .mustEmailInner .meBody a {
  text-decoration: none;
}
.mustEmailDlg .mustEmailInner .meBody a:hover {
  text-decoration: underline;
}

.noProdsFoundDiv {
  margin-top: 5vh;
  text-align: center;
  color: #3128b1d1;
  font-size: calc(14px + 1vw);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 415px) {
  .buttonsDiv button {
    width: 200px !important;
  }
}