.autocompleterDiv {
  grid-area: logosrx;
  z-index: 9;
  position: absolute;
  xxxoverflow: hidden;
  /*  No! Hides the Srx results column  */
  width: 100%;
  height: 100%;
  min-height: 5vh;
  margin-left: calc(1px + 0.5vw);
}

.searchDiv {
  position: absolute;
  z-index: 9;
  display: flex;
  height: 100%;
  font-size: calc(5px + 0.8vw);
}
.searchDiv .srxInputDiv {
  height: 100%;
  max-width: 30%;
  min-width: calc(30px + 10vw);
  margin-right: 2px;
  display: flex;
  align-items: center;
}
.searchDiv .srxInputDiv .srxInputField {
  width: 100%;
  outline: unset;
  padding: 0.1vw 0.25vw;
  font-size: calc(5px + 0.9vw);
  border: 1px solid rgba(200, 200, 200, 0.5);
}
.searchDiv .srxInputDiv .srxInputField:focus {
  border: 1px solid rgba(50, 100, 200, 0.5);
}
.searchDiv .srxInputDiv .srxInputField::placeholder {
  color: #ccc;
  font-family: "Segoe UI light", "Segoe UI", Arial, Helvetica, sans-serif;
}
.searchDiv .srxResultsDiv {
  max-width: 20vw;
}
.searchDiv .srxResultsDiv ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: rgba(250, 250, 250, 0.9);
}
.searchDiv .srxResultsDiv ul li {
  min-width: 230px;
  padding: 0 10px;
  color: #333;
  cursor: pointer;
}
.searchDiv .srxResultsDiv ul li:hover {
  background: #4774b354 !important;
}

.liHoverBg {
  background: #4774b354 !important;
}

.minLeftMargin {
  margin-left: 1vw !important;
}

@media (max-width: 649px) {
  .srxResultsDiv {
    max-width: 30vw !important;
  }

  .srxResultsDiv ul li {
    font-size: calc(3px + 2vw) !important;
  }

  .autocompleterDiv {
    width: 90vw;
  }
}
@media (min-width: 650px) and (max-width: 1921px) {
  .srxResultsDiv {
    max-width: 20vw !important;
  }

  .srxResultsDiv ul li {
    font-size: 14px !important;
  }
}