.overlay {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.createAccountModal {
  z-index: 9;
  position: fixed;
  top: 20vh;
  left: 27.5vw;
  width: 45vw;
  height: fit-content;
  padding: 10px;
  color: #444;
  background: white;
}
.createAccountModal .accmodTop {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}
.createAccountModal .accmodTop .accmodTopText {
  width: 80%;
  font-size: 1.2em;
  font-weight: bold;
}
.createAccountModal .accmodTop .accmodCloseX {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  padding-bottom: 3px;
  color: white;
  background: #0073ea;
  border: 2px solid #0073ea;
}
.createAccountModal .accmodTop .accmodCloseX:hover {
  cursor: pointer;
  color: #0073ea;
  font-weight: bold;
  background: white;
}
.createAccountModal .accmodBody {
  display: flex;
  padding: 10px;
  font-size: 0.9em;
}
.createAccountModal .accmodBody .accmodBodyLeft {
  flex: 1;
  max-width: 40vw;
}
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow input[type=text],
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow input[type=password] {
  width: 100%;
}
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow input[type=text]::placeholder,
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow input[type=password]::placeholder {
  font-size: 0.8em;
  color: #bbb;
}
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow .accmodFRLTitle {
  width: 10vw;
  max-width: 80px;
  text-align: left;
}
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow .accmodFRLInput {
  flex: 1;
  max-width: 40%;
  margin-right: 10px;
}
.createAccountModal .accmodBody .accmodBodyLeft .accmodFieldRow .accmodFRLInput input {
  xxxfont-size: 1rem;
  padding: 3px 7px;
}
.createAccountModal .accmodBody .accmodBodyRight {
  flex: 1;
  max-width: 15vw;
  padding-left: 20px;
  border-left: 1px solid #ccc;
}
.createAccountModal .accmodBody .accmodBodyTitle {
  margin-bottom: 10px;
  font-size: 1.5em;
}
.createAccountModal .accmodBody .accmodBtnRight {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
.createAccountModal .accmodBody .ibutton {
  margin: 0;
  padding: 5px 10px;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  background: #0073ea;
  border: 2px solid #0073ea;
}
.createAccountModal .accmodBody .ibutton:hover {
  cursor: pointer;
  color: #0073ea;
  background: white;
}

.form-control {
  height: 34px;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  xxxborder-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.flex-parent {
  display: flex;
}

.fgGrey {
  color: #555;
}

.regSelect {
  padding: 2px 5px;
}

.captcha {
  padding: 5px 10px;
  border: 1px solid #ccc;
}
.captcha .capAns {
  display: flex;
  width: 15vw;
}

.capErr {
  background: #ffc0cb61;
  /* pink with opacity .5 */
}

.formControl {
  padding: 2px 5px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 5px;
}

.thankYou {
  width: 10vw;
  text-align: center;
  font-style: italic;
  font-size: 0.9em;
}

.linkContinue {
  width: 10vw;
  color: #777;
  cursor: default;
  text-align: center;
  font-style: italic;
  font-size: 0.9em;
}

.contLinkOkay {
  color: blue;
  cursor: pointer;
}

.messageHide {
  max-height: 0;
  overflow: hidden;
}

.messageShow {
  max-height: 110px;
  overflow: visible;
  transition: max-height 0.25s ease-in;
}

.taMessage {
  width: 200%;
  height: 50px;
}

.plsCompleteMsg {
  color: blue;
  font-size: calc(5px + 0.5vw);
  font-style: italic;
}