.rdDiv {
  padding: 5vh 12vw;
  color: #444 !important;
  background: #e6e6e6;
}
.rdDiv .pageContentFromDB {
  width: 100%;
  height: fit-content;
}
.rdDiv .rdPresentationsFromDB {
  width: 100%;
  height: fit-content;
}
.rdDiv .rdPresentationsFromDB .singlePres {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-areas: "img data";
  border: 1px solid rgba(250, 250, 250, 0.5);
  padding: 1vh 2vw;
}
.rdDiv .rdPresentationsFromDB .presImg {
  grid-area: img;
  display: flex;
  justify-content: center;
}
.rdDiv .rdPresentationsFromDB .presData {
  grid-area: data;
}
.rdDiv .rdPresentationsFromDB .presData .presTitle {
  font-size: 1.4rem;
  font-weight: bold;
}
.rdDiv .rdPresentationsFromDB .presData .presBlurb {
  font-size: 1.1rem;
  font-style: italic;
}

.pdfAnchor a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #678cbf;
}
.pdfAnchor .pdfRequestOnly {
  font-size: 1.1em;
}
.pdfAnchor .pdfRequestOnly a {
  font-size: 1.1em;
}