.text-area {
  resize: vertical;
  width: 100%;
  min-height: 250px;
  max-height: 500px;
  padding: 10px;
  border-radius: 5px;
}
.text-area:disabled {
  cursor: not-allowed;
  background: #373737;
}
.text-area::placeholder {
  color: #ccc;
}